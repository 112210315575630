.form-control {
  font-family: Montserrat;
}

.info-msg {
  color: #ff6a3c;
  font-weight: bold;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
}

.twitter-bs-wizard .twitter-bs-wizard-nav {
  position: relative;
}

.twitter-bs-wizard .twitter-bs-wizard-nav:before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #e3e6e8;
  position: absolute;
  left: 0;
  top: 26px;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 34px;
  border: 2px solid #fe7648;
  color: #fe7648;
  text-align: center;
  border-radius: 50%;
  position: relative;
  background-color: white;
}

@media (max-width: 991.98px) {
  .twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
    display: block;
    margin: 0 auto 8px !important;
  }
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link .step-title {
  display: block;
  margin-top: 8px;
  font-weight: bold;
}

@media (max-width: 575.98px) {
  .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link .step-title {
    display: none;
  }
}

.nav-link {
  color: #aab0b5;
  cursor: pointer;
  font-family: Montserrat;
}

.nav-link:hover {
  color: #fe7648;
  cursor: pointer;
}

input,
label {
  font-family: Montserrat;
  font-size: 14px;
}

.Form-title {
  font-family: monopola;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 99;
  display: flex;
  justify-content: center; /* Center the Navbar horizontally */
  align-items: center; /* Center the Navbar vertically */
}
.responsive-container {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}
.booking-info-container{
  display: flex;
  align-items: center;
  padding-top: 11rem;
}
@media (min-width: 1024px) {
  .responsive-container {
    padding-bottom: 4rem;
  }
  .booking-info-container{
    padding-top: 8rem;
  }
}

@media (max-width: 767px) {
  /* .back {
    margin-top: 27%; 
  } */
  .address-proof {
    width: 50%;
  }
}

@media (min-width: 767px) {
  .back {
    margin-top: 8%; /* Adjust the margin value as needed */
    margin-left: 2%;
  }
  .address-proof {
    width: 25%;
  }
}